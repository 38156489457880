import React, { useEffect, useState, useCallback } from "react";
import { LdsHeader, LdsLillyLogo, LdsSelect, LdsModal, useLdsModal, LdsButton} from "@elilillyco/ux-lds-react";
// import { menuItemsConfig } from "./headerConfig";
import { Logo } from "../../../Components/common/Logo";
import { OrcaUser } from "../../../Components/common/OrcaUser";
import { RequestForm } from "../../../Components/requests/RequestForm";
import { getServiceCatalogData, getLoadingState as getServiceCatalogLoadingState, selectServiceCatalogData } from "../../../redux/slices/serviceCatalogSlice";
import store from "../../../redux/store";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../redux/slices/userSlice";
import { getMlData } from '../../../redux/slices/mlSlice'; 

export default function DashboardNavBar(props) {
  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const userData = useSelector(selectUserData);
  useEffect(() => {
    store.dispatch(getServiceCatalogData());
  }, []);
  useEffect(() => {
    store.dispatch(getMlData());
  }, []);
  const menuConfigurationArray = [
    {
      itemText: 'Create Request',
      type: 'link',
      iconName: 'PlusCircleFill',
      href: '#modal',
      externalCallback: () => { setIsModalOpen(true)}
    },
    {
      itemText: 'Menu',
      type: 'button',
      submenuItems: [
        {
          itemText: 'Enhancement Requests',
          href: '/requests',
        },
        {
          itemText: 'Consolidated Insights Library',
          href: '/csl',
        },
        {
          itemText: 'Service Catalog',
          href: '/ServiceCatalog',
        },
        {
          itemText: 'Sprint Dates',
          href: '/SprintDates',
        },
        {
          itemText: 'Visual Graph Library',
          href: '/ml',
        },
      ],
    },
    userData.role === 'Admin' && {
      itemText: 'Admin',
      type: 'button',
      submenuItems: [
        {
          itemText: 'Consolidated Insights Library Management',
          href: '/cilAdmin',
        },
        {
          itemText: 'Brand Indication Management',
          href: '/brandindicationAdmin',
        },
        {
          itemText: 'Metrics Library Management',
          href: '/MetricsLibraryAdmin',
        },
        {
          itemText: 'Sprint dates Management',
          href: '/SprintDateAdmin',
        },
      ],
    }
  ];
  // iterate all items in menuConfigurationArray and set the current property to true if the href matches the current window location
  menuConfigurationArray.forEach((item) => {
    if (item.href && item.href === window.location.pathname) {
      item.current = true;
    }
    if (item.submenuItems) {
      item.submenuItems.forEach((subItem) => {
        if (subItem.href && subItem.href === window.location.pathname) {
          item.current = true;
        }
      });
    }
  });

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const isRequester = () => {
    if (userData.role === 'Admin' || userData.role === 'Requester') {
      return true;
    }
    return false;
  }

  return (
    <>
      <LdsModal
          modalId="requestModal"
          open={isModalOpen}
          setModalOpen={setIsModalOpen}
          heading="New Request"
          persistent
      >
        <LdsButton
          clearDefaultClasses
          className="lds-button-clear-style lds-modal-close-btn focus icon"
          onClick={() => setIsModalOpen(false)}
          icon="X"
          iconOnly
        />
      { isRequester() && <RequestForm closeModal={closeModal} editable={isRequester()} /> }
      </LdsModal>
      <LdsHeader
        customLogo={<Logo/>}

        menuItems={menuConfigurationArray}
        menuAlignment={"right"}
        style={{'height':'112px'}}
      >

        <OrcaUser></OrcaUser>
        <div></div>
      </LdsHeader>
    </>
  );
}
